import React, { useEffect } from "react";
import { DashboardViewModel } from "../../viewmodels/dashboard/DashboardViewModel";

import OpenOrdersCard from "./cards/OpenOrdersCard";
import ShippedOrdersCard from "./cards/ShippedOrdersCard";
import InStockTwoMonthsCard from "./cards/InStockTwoMonthsCard";
import RecentProductionCard from "./cards/RecentProductionCard";
import RecentlyPackagedCard from "./cards/RecentlyPackagedCard";
import LowRawInventoryCard from "./cards/LowRawInventoryCard";
import SummaryCards from "./summaryCards/SummaryCards";
import BelowMaxThresholdCard from "./cards/BelowMaxThresholdCard";
import StatsComponents from "../../components/stats/StatsComponents";

interface DashboardProps {
  logout: () => void;
}

const stats = [
  {
    name: "Total Orders",
    subtitle: null,
    stat: "$15,827",
    previousStat: "1",
    change: "+12%",
    changeType: "increase",
  },
  {
    name: "Total Shipped Orders",
    subtitle: null,
    stat: "$0",
    previousStat: "2",
    change: "+0%",
    changeType: "decrease",
  },
  {
    name: "Produced Products",
    subtitle: null,
    stat: "24,911",
    previousStat: "3",
    change: "+4%",
    changeType: "decrease",
  },
  {
    name: "Packaged Products",
    subtitle: null,
    stat: "$24,990",
    previousStat: "",
    change: "+1%",
    changeType: "increase",
  },
  {
    name: "Low In Stock",
    subtitle: null,
    stat: "$20,192",
    previousStat: "",
    change: "-2%",
    changeType: "decrease",
  },
  {
    name: "Received Amount",
    subtitle: null,
    stat: "$8,725",
    previousStat: "",
    change: "+3%",
    changeType: "increase",
  },
];

const info3 = {
  data: [
    {
      item1: "2024060701",
      item2: "SMOKED OVEN ROASTED TURKEY BREAST SLICED",
      item3: "2024-10-05",
    },
    {
      item1: "2024060602",
      item2: "CHICKEN BURGER 3 INCH",
      item3: "2025-06-06",
    },
    {
      item1: "2024060601",
      item2: "BBQ DEBREZINER SAUSAGE",
      item3: "2025-06-06",
    },
    {
      item1: "2024061005",
      item2: "ALL BEEF SALAMI MILD",
      item3: "2025-06-10",
    },
    {
      item1: "2024061003",
      item2: "BOLOGNA MILD",
      item3: "2025-06-10",
    },
  ],
  name: "In Stock (Over 2 Months)",
};

const Dashboard: React.FC<DashboardProps> = ({ logout }) => {
  const {
    getInfo1,
    getInfo2,
    getInfo3,
    getInfo4,
    getInfo5,
    getInfo6,
    getInfo7,
    info1,
    info2,
    // info3,
    info4,
    info5,
    info6,
    info7,
    info1Loading,
    info2Loading,
    info3Loading,
    info4Loading,
    info5Loading,
    info6Loading,
    info7Loading,
  } = DashboardViewModel(logout);

  useEffect(() => {
    // Uncomment the following lines to fetch data on mount
    // getInfo1();
    // getInfo2();
    getInfo3();
    // getInfo4();
    // getInfo5();
    // getInfo6();
    // getInfo7();
  }, []);

  const analytics = {
    range: "Welcome back Jason",
    stats: stats,
    loading: false,
  };

  return (
    <div>
      <div className="mt-2 pb-4 px-4 sm:px-6 lg:px-8">
        <div>
          <StatsComponents analytics={analytics} />
        </div>
        <div className="mt-8">
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
            {/* Uncomment the following lines to display the cards */}

            <InStockTwoMonthsCard info={info3} loading={info3Loading} />
            {/* <RecentProductionCard info={info4} loading={info4Loading} />
            <RecentlyPackagedCard info={info5} loading={info5Loading} />
            <LowRawInventoryCard info={info6} loading={info6Loading} />
            <BelowMaxThresholdCard info={info7} loading={info7Loading} />
            <OpenOrdersCard info={info1} loading={info1Loading} />
            <ShippedOrdersCard info={info2} loading={info2Loading} /> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
