import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

import { classNames, formatDateWithTime } from "../../../shared/utility";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

export default function InStockTwoMonthsCard(props) {
  const { info } = props;

  return (
    <li className="overflow-hidden rounded-xl border border-gray-200 shadow-md">
      <div className="flex infos-center gap-x-4 border-b border-gray-900/5 bg-gray-50 px-3 py-4">
        <div className="text-sm font-medium leading-6 text-gray-900">
          {info.name}
        </div>
        <Link
          className="relative ml-auto text-sm font-semibold leading-6 text-indigo-700 flex"
          to={"/my-products/new"}
        >
          View All
          <ArrowRightIcon className="h-4 w-4 ml-2 my-auto" aria-hidden="true" />
        </Link>
      </div>

      <div className="overflow-hidden bg-white h-full">
        <table className="min-w-full">
          <thead>
            <tr className="border-b border-gray-300">
              <th
                scope="col"
                className="py-3 pl-1 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                style={{ width: "15%" }}
              >
                Product Lot
              </th>
              <th
                scope="col"
                className="px-1 py-1 text-left text-sm font-semibold text-gray-900"
                style={{ width: "50%" }}
              >
                name
              </th>
              <th
                scope="col"
                className="px-1 py-1 text-left text-sm font-semibold text-gray-900"
                style={{ width: "35%" }}
              >
                Expiry Date
              </th>
            </tr>
          </thead>
          <tbody>
            {info.data.length > 0 ? (
              info.data.map((product) => (
                <tr key={product.item1}>
                  <td className="py-1.5 pl-2 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                    {product.item1}
                  </td>
                  <td className="px-2 py-1.5 text-sm text-gray-500">
                    {product.item2}
                  </td>
                  <td className="px-2 py-1.5 text-sm text-gray-500">
                    {formatDateWithTime(product.item3)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center"></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </li>
  );
}

{
  /* <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        {info.data.map((item) => (
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">{item.item1}</dt>
            <dd className="text-gray-700">{item.item2}</dd>
            {item.item3 ? (
              <dd className="text-gray-700">{item.item3}</dd>
            ) : null}
          </div>
        ))}
      </dl> */
}
